var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(" "+_vm._s(_vm.formEditTitle))])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.updateTarea()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.periodicidadItems,"item-text":"value","item-value":"id","return-object":"","label":"Periodicidad","outlined":"","dense":""},on:{"change":_vm.setCombosByPeriodicidad},model:{value:(_vm.periSelected),callback:function ($$v) {_vm.periSelected=$$v},expression:"periSelected"}})],1),(_vm.isPeriSemana)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.diasSemanaItems,"item-text":"value","item-value":"id","return-object":"","label":"Día","rules":_vm.rules.required,"outlined":"","multiple":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.diaSelected.length - 1)+" otros) ")]):_vm._e()]}}],null,false,3735605619),model:{value:(_vm.diaSelected),callback:function ($$v) {_vm.diaSelected=$$v},expression:"diaSelected"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2","align":"center"}},[_c('v-menu',{ref:"menuHoraDesde",attrs:{"close-on-content-click":false,"return-value":_vm.selectedTimeDesde,"transition":"scale-transition","offset-y":"","nudge-bottom":0,"max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.selectedTimeDesde=$event},"update:return-value":function($event){_vm.selectedTimeDesde=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora desde","append-icon":"mdi-clock","outlined":"","dense":"","readonly":"","hint":"Formato HH:MM","rules":_vm.rules.required.concat(
                      _vm.selectedTimeDesde < _vm.selectedTimeHasta ||
                        'Formato incorrecto'
                    )},on:{"focus":function($event){_vm.menuHoraDesde = true},"input":function($event){_vm.menuHoraDesde = false},"blur":function($event){return _vm.$refs.menuHoraDesde.save(_vm.selectedTimeDesde)}},model:{value:(_vm.selectedTimeDesde),callback:function ($$v) {_vm.selectedTimeDesde=$$v},expression:"selectedTimeDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHoraDesde),callback:function ($$v) {_vm.menuHoraDesde=$$v},expression:"menuHoraDesde"}},[(_vm.menuHoraDesde)?_c('v-time-picker',{staticClass:"mt-4",attrs:{"scrollable":"","no-title":"","allowed-minutes":[0, 30],"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menuHoraDesde.save(_vm.selectedTimeDesde)}},model:{value:(_vm.selectedTimeDesde),callback:function ($$v) {_vm.selectedTimeDesde=$$v},expression:"selectedTimeDesde"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menuHoraHasta",attrs:{"close-on-content-click":false,"return-value":_vm.selectedTimeHasta,"transition":"scale-transition","offset-y":"","nudge-bottom":0,"max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.selectedTimeHasta=$event},"update:return-value":function($event){_vm.selectedTimeHasta=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora hasta","append-icon":"mdi-clock","outlined":"","dense":"","readonly":"","hint":"Formato HH:MM","rules":_vm.rules.required.concat(
                      _vm.selectedTimeDesde < _vm.selectedTimeHasta ||
                        'Formato incorrecto'
                    )},on:{"focus":function($event){_vm.menuHoraHasta = true},"input":function($event){_vm.menuHoraHasta = false},"blur":function($event){return _vm.$refs.menuHoraHasta.save(_vm.selectedTimeHasta)}},model:{value:(_vm.selectedTimeHasta),callback:function ($$v) {_vm.selectedTimeHasta=$$v},expression:"selectedTimeHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHoraHasta),callback:function ($$v) {_vm.menuHoraHasta=$$v},expression:"menuHoraHasta"}},[(_vm.menuHoraHasta)?_c('v-time-picker',{staticClass:"mt-4",attrs:{"scrollable":"","no-title":"","allowed-minutes":[0, 30],"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menuHoraHasta.save(_vm.selectedTimeHasta)}},model:{value:(_vm.selectedTimeHasta),callback:function ($$v) {_vm.selectedTimeHasta=$$v},expression:"selectedTimeHasta"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"mt-3",attrs:{"label":"Habilitada"},model:{value:(_vm.habilitada),callback:function ($$v) {_vm.habilitada=$$v},expression:"habilitada"}})],1)],1),(_vm.parametrosEnable)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":_vm.rules.required.concat([
                  _vm.rules.requiredTrim(_vm.parametros),
                  _vm.rules.maxLength(_vm.parametros, 70)
                ]),"label":"Parámetros"},model:{value:(_vm.parametros),callback:function ($$v) {_vm.parametros=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"parametros"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":"","label":"Descripción parámetros"},model:{value:(_vm.descripParametros),callback:function ($$v) {_vm.descripParametros=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"descripParametros"}})],1),(this.itemTarea.parametrosEjemplo.includes(','))?_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"1","md":"1","sm":"1"}},[_c('v-tooltip',{attrs:{"right":"","max-width":"10rem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pt-2",attrs:{"size":"1.4rem","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon))])]}}],null,false,3664297106)},[_c('span',[_vm._v("Se deben ingresar los parámetros separados por coma. Ejemplo: "+_vm._s(this.itemTarea.parametrosEjemplo))])])],1):_vm._e()],1):_vm._e(),(_vm.parametrosEnable)?_c('v-row',{staticClass:"py-0 pl-4 pb-2"},[_c('h5',{staticClass:"pl-0 primary--text py-0",attrs:{"small":""}},[_vm._v(" Datos de conexión: ")])]):_vm._e(),(_vm.parametrosEnable)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":_vm.parametrosEnable
                  ? _vm.rules.required.concat([
                      _vm.rules.requiredTrim(_vm.servidorNomToConnectionString),
                      _vm.rules.maxLength(_vm.servidorNomToConnectionString, 70)
                    ])
                  : [],"label":"Nombre/IP del servidor"},model:{value:(_vm.servidorNomToConnectionString),callback:function ($$v) {_vm.servidorNomToConnectionString=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"servidorNomToConnectionString"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":_vm.parametrosEnable
                  ? _vm.rules.required.concat([
                      _vm.rules.requiredTrim(_vm.userToConnectionString),
                      _vm.rules.maxLength(_vm.userToConnectionString, 20)
                    ])
                  : [],"label":"Usuario SQL Server"},model:{value:(_vm.userToConnectionString),callback:function ($$v) {_vm.userToConnectionString=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userToConnectionString"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":_vm.parametrosEnable
                  ? _vm.rules.required.concat([
                      _vm.rules.requiredTrim(_vm.passToConnectionString),
                      _vm.rules.maxLength(_vm.passToConnectionString, 20)
                    ])
                  : [],"label":"Contraseña SQL Server","append-icon":!_vm.passToConnectionString ? '' : _vm.textType ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.textType ? 'password' : 'text'},on:{"click:append":function () { return (_vm.textType = !_vm.textType); },"focus":function($event){_vm.inputActive = 'password'},"blur":function($event){_vm.inputActive = ''}},model:{value:(_vm.passToConnectionString),callback:function ($$v) {_vm.passToConnectionString=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"passToConnectionString"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid || (_vm.diaSelected.length == 0 && _vm.isPeriSemana),"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }