<template>
  <v-card>
    <v-container>
      <v-card-title class="pb-0">
        <v-col cols="12" md="12" sm="12" class="py-0 pl-0 pb-2">
          <PageHeader :title="titelLog" />
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-card class="mr-0">
          <v-data-table
            ref="log"
            :headers="headersLogEjecuciones"
            no-data-text="No hubo ejecuciones"
            :items="logEjecucionesItems"
          >
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-actions class="py-0 pr-4">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";

export default {
  name: "VerLogTareasProgramadas",
  components: {
    PageHeader
  },
  props: {
    tareaNombre: { type: String, required: false, default: null },
    tareaId: { type: Number, required: false, default: null }
  },
  created() {
    this.getLogTareasById();
  },
  data() {
    return {
      titelLog: "Historial ejecuciones: " + this.tareaNombre,
      logEjecucionesItems: [],
      headersLogEjecuciones: [
        {
          text: "Fecha y hora de ejecución",
          align: "start",
          value: "ultimaEjecucion",
          sortable: false
        },
        {
          text: "Estado de ejecución",
          align: "left",
          value: "estadoEjecucion",
          sortable: false
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      getLogTareasProgramacion: "AdministracionSistema/getLogTareasProgramacion"
    }),
    async getLogTareasById() {
      const response = await this.getLogTareasProgramacion(this.tareaId);
      this.logEjecucionesItems = response;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
