<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text"> {{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="updateTarea()"
        >
          <v-row>
            <v-col cols="3">
              <v-autocomplete
                v-model="periSelected"
                :items="periodicidadItems"
                item-text="value"
                @change="setCombosByPeriodicidad"
                item-value="id"
                return-object
                label="Periodicidad"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col v-if="isPeriSemana" cols="3">
              <v-autocomplete
                v-model="diaSelected"
                :items="diasSemanaItems"
                item-text="value"
                item-value="id"
                return-object
                label="Día"
                :rules="rules.required"
                outlined
                multiple
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ diaSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" align="center">
              <v-menu
                ref="menuHoraDesde"
                v-model="menuHoraDesde"
                :close-on-content-click="false"
                :return-value.sync="selectedTimeDesde"
                transition="scale-transition"
                offset-y
                :nudge-bottom="0"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedTimeDesde"
                    label="Hora desde"
                    append-icon="mdi-clock"
                    outlined
                    dense
                    readonly
                    hint="Formato HH:MM"
                    @focus="menuHoraDesde = true"
                    @input="menuHoraDesde = false"
                    @blur="$refs.menuHoraDesde.save(selectedTimeDesde)"
                    :rules="
                      rules.required.concat(
                        selectedTimeDesde < selectedTimeHasta ||
                          'Formato incorrecto'
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  scrollable
                  no-title
                  v-if="menuHoraDesde"
                  :allowed-minutes="[0, 30]"
                  v-model="selectedTimeDesde"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.menuHoraDesde.save(selectedTimeDesde)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                ref="menuHoraHasta"
                v-model="menuHoraHasta"
                :close-on-content-click="false"
                :return-value.sync="selectedTimeHasta"
                transition="scale-transition"
                offset-y
                :nudge-bottom="0"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedTimeHasta"
                    label="Hora hasta"
                    append-icon="mdi-clock"
                    outlined
                    dense
                    readonly
                    hint="Formato HH:MM"
                    @focus="menuHoraHasta = true"
                    @input="menuHoraHasta = false"
                    :rules="
                      rules.required.concat(
                        selectedTimeDesde < selectedTimeHasta ||
                          'Formato incorrecto'
                      )
                    "
                    @blur="$refs.menuHoraHasta.save(selectedTimeHasta)"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  scrollable
                  no-title
                  v-if="menuHoraHasta"
                  :allowed-minutes="[0, 30]"
                  v-model="selectedTimeHasta"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.menuHoraHasta.save(selectedTimeHasta)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-switch
                class="mt-3"
                v-model="habilitada"
                label="Habilitada"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="parametrosEnable">
            <v-col cols="3" class="py-0">
              <v-text-field
                v-model.trim="parametros"
                outlined
                dense
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(parametros),
                    rules.maxLength(parametros, 70)
                  ])
                "
                label="Parámetros"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="descripParametros"
                outlined
                dense
                disabled
                label="Descripción parámetros"
              >
              </v-text-field>
            </v-col>
            <!-- si hay comas es porque hay mas de un parametro, y se debe mostrar la info -->
            <v-col
              v-if="this.itemTarea.parametrosEjemplo.includes(',')"
              cols="1"
              md="1"
              sm="1"
              class="py-0 px-0"
            >
              <v-tooltip right max-width="10rem">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="1.4rem"
                    class="pt-2"
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                  >
                    {{ infoIcon }}</v-icon
                  >
                </template>
                <span
                  >Se deben ingresar los parámetros separados por coma. Ejemplo:
                  {{ this.itemTarea.parametrosEjemplo }}</span
                >
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="parametrosEnable" class="py-0 pl-4 pb-2">
            <h5 small class="pl-0 primary--text py-0">
              Datos de conexión:
            </h5>
          </v-row>
          <v-row v-if="parametrosEnable">
            <v-col cols="3" class="py-0">
              <v-text-field
                v-model.trim="servidorNomToConnectionString"
                outlined
                dense
                :rules="
                  parametrosEnable
                    ? rules.required.concat([
                        rules.requiredTrim(servidorNomToConnectionString),
                        rules.maxLength(servidorNomToConnectionString, 70)
                      ])
                    : []
                "
                label="Nombre/IP del servidor"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-text-field
                v-model.trim="userToConnectionString"
                outlined
                dense
                :rules="
                  parametrosEnable
                    ? rules.required.concat([
                        rules.requiredTrim(userToConnectionString),
                        rules.maxLength(userToConnectionString, 20)
                      ])
                    : []
                "
                label="Usuario SQL Server"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-text-field
                v-model.trim="passToConnectionString"
                outlined
                dense
                :rules="
                  parametrosEnable
                    ? rules.required.concat([
                        rules.requiredTrim(passToConnectionString),
                        rules.maxLength(passToConnectionString, 20)
                      ])
                    : []
                "
                label="Contraseña SQL Server"
                :append-icon="
                  !passToConnectionString ? '' : textType ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append="() => (textType = !textType)"
                :type="textType ? 'password' : 'text'"
                @focus="inputActive = 'password'"
                @blur="inputActive = ''"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || (diaSelected.length == 0 && isPeriSemana)"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";

export default {
  name: "EditTareasProgramadas",
  components: {
    PageHeader
  },
  props: {
    itemTarea: { type: Object, required: false, default: null }
  },
  data() {
    return {
      textType: String,
      inputActive: "",
      formEditTitle: "Tarea: " + this.itemTarea.tareaNombre,
      rules: rules,
      infoIcon: enums.icons.SNB_INFO,
      editIcon: enums.icons.EDIT,
      mSCloseBoxIcon: enums.icons.CLOSE_BOX,
      checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
      tareasItem: [],
      openModalEdit: false,
      isFormValid: true,
      canDisable: true,
      tareaSelected: null,
      diaSelected: [],
      diasSelectedConvert: "",
      selectedTimeDesde: null,
      menuHoraDesde: false,
      menuHoraHasta: false,
      selectedTimeHasta: null,
      habilitada: true,
      isPeriDia: false,
      isPeriSemana: false,
      periSelected: [],
      parametros: null,
      servidorNomToConnectionString: "",
      userToConnectionString: "",
      passToConnectionString: "",
      descripParametros: "",
      periodicidadItems: [
        {
          id: "D",
          value: "Diariamente"
        },
        {
          id: "S",
          value: "Semanalmente"
        }
      ],
      diasSemanaItems: [
        { id: "L", value: "Lunes" },
        { id: "MA", value: "Martes" },
        { id: "MI", value: "Miércoles" },
        { id: "J", value: "Jueves" },
        { id: "V", value: "Viernes" },
        { id: "S", value: "Sábado" },
        { id: "D", value: "Domingo" }
      ],
      parametrosEnable: false
    };
  },
  created() {
    this.setTareaData(this.itemTarea);
  },
  methods: {
    ...mapActions({
      updateTareas: "AdministracionSistema/updateTareas",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("closeAndReload");
    },
    setCombosByPeriodicidad() {
      if (this.periSelected.id === "D") {
        this.isPeriDia = true;
        this.isPeriSemana = false;
      }
      if (this.periSelected.id === "S") {
        this.isPeriSemana = true;
        this.isPeriDia = false;
      }
    },
    setTareaData(item) {
      if (item.habilitarParametros) this.parametrosEnable = true;
      this.selectedTimeDesde = item.horaEjecucionDesdeStr;
      this.parametros = item.parametros;
      this.servidorNomToConnectionString = item.servidorNomToConnectionString;
      this.userToConnectionString = item.userToConnectionString;
      this.passToConnectionString = item.passToConnectionString;
      this.descripParametros = item.parametrosDescrip;
      this.selectedTimeHasta = item.horaEjecucionHastaStr;
      this.habilitada = item.habilitada;
      this.periSelected.id = item.periodicidad;
      this.diasSemanaItems.forEach(x => {
        if (item.diasSemana.includes(x.id)) this.diaSelected.push(x);
      });
      this.setCombosByPeriodicidad();
    },
    async updateTarea() {
      this.diasSelectedConvert = "";
      this.diaSelected.forEach(x => {
        this.diasSelectedConvert += x.id;
      });
      const data = {
        tareaId: this.itemTarea.tareaId,
        periodicidad: this.periSelected.id,
        diasSemana: this.diasSelectedConvert,
        horaEjecucionDesde: this.selectedTimeDesde,
        horaEjecucionHasta: this.selectedTimeHasta,
        habilitada: this.habilitada,
        parametros: this.itemTarea.habilitarParametros ? this.parametros : "",
        servidorNomToConnectionString: this.itemTarea.habilitarParametros
          ? this.servidorNomToConnectionString
          : "",
        userToConnectionString: this.itemTarea.habilitarParametros
          ? this.userToConnectionString
          : "",
        passToConnectionString: this.itemTarea.habilitarParametros
          ? this.passToConnectionString
          : ""
      };
      const res = await this.updateTareas(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
        this.isFormValid = true;
      }
    }
  }
};
</script>
